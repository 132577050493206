import React, { useState, useEffect } from "react";

const PlayerCount = () => {
  const startDate = new Date("2024-01-12"); // Replace with your start date
  const baseCount = 8;

  const calculatePlayerCount = () => {
    const today = new Date();
    const timeDifference = today - startDate; // Difference in milliseconds
    const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
    return baseCount + daysPassed; // Increment base count by days passed
  };

  const [playerCount, setPlayerCount] = useState(calculatePlayerCount());

  // Update player count every day
  useEffect(() => {
    const interval = setInterval(() => {
      setPlayerCount(calculatePlayerCount());
    }, 1000 * 60 * 60 * 24); // Update every 24 hours

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20px" }}>
      <div className="fs-5 mt-2" style={{ color: "#F5F704" }}>Visited Player's</div>
      {/* <div className="" style={{ color: "white" }}>{playerCount}</div> */}
      <div className="" style={{ color: "white" }}>72</div>
    </div>
  );
};

export default PlayerCount;
