import React, { useEffect, useState } from "react";
import SANTA from "./assets/sant.png";
import SANTALOGO from "./assets/santalogo.png";
import HNB from "./assets/hnb.png";
import MIC from "./assets/mic_icon.png";
import STRT_BTN from "./assets/start_btn.png";
import CLOCK from "./assets/clock.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import { STRINGS } from "./common/strings";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  OKIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
} from "react-share";
import { FaFacebook, FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import PlayerCount from "./PlayerCount";

function App() {
  const [imagePosition, setImagePosition] = useState(0); // Start at the bottom
  const [points, setPoints] = useState(0); // User's score
  const [isGameActive, setIsGameActive] = useState(false); // Game state
  const [timeLeft, setTimeLeft] = useState(0); // Countdown timer
  const [langaugeCode, setLangaugeCode] = useState(0);
  const THRESHOLD = 25; // Minimum volume to trigger movement
  const BASELINE = 10; // Baseline to ignore very small noises

  useEffect(() => {
    let audioContext, analyser, microphone, javascriptNode;
    let gravityTimer;
  
    const startAudio = async () => {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
  
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      microphone = audioContext.createMediaStreamSource(stream);
      javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
  
      microphone.connect(analyser);
      analyser.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
  
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
  
      javascriptNode.onaudioprocess = () => {
        if (!isGameActive) return;
  
        analyser.getByteFrequencyData(dataArray);
        const volume =
          dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
  
        console.log("Raw Volume:", volume);
  
        let upwardForce = 0;
        if (volume >= THRESHOLD) {
          const adjustedVolume = Math.max(0, volume - BASELINE);
  
          if (adjustedVolume >= 10 && adjustedVolume < 50) {
            // Green Zone (Easy)
            upwardForce = adjustedVolume / 4; // Easier to climb
          } else if (adjustedVolume >= 50 && adjustedVolume < 80) {
            // Yellow Zone (Moderate)
            upwardForce = adjustedVolume / 4; // Moderate difficulty
          } else if (adjustedVolume >= 80) {
            // Red Zone (Hard)
            upwardForce = Math.min(adjustedVolume / 15, 10); // Very hard to climb
          }
          console.log("IM", adjustedVolume);
        }
  
        setImagePosition((prev) => {
          const newPosition = Math.min(100, prev + upwardForce);
          if (newPosition >= 95) {
            setIsGameActive(false); // Stop the game if the position reaches 90 or above
          }
          return newPosition;
        });
  
        if (upwardForce > 0) {
          setPoints((prevPoints) => prevPoints + Math.round(upwardForce));
        }
      };
    };
  
    if (isGameActive) {
      startAudio();
  
      // Gravity effect that pulls the image down automatically
      gravityTimer = setInterval(() => {
        setImagePosition((prev) => {
          if (prev > 0) {
            return prev - 5; // Moves the image down by 5 units per interval
          }
          return prev;
        });
      }, 40);
    }
  
    return () => {
      if (audioContext) audioContext.close();
      clearInterval(gravityTimer); // Clear gravity timer when the component unmounts
    };
  }, [isGameActive]);
  
  const startGame = () => {
    setPoints(0); // Reset points
    setImagePosition(0); // Reset image position
    setIsGameActive(true); // Start the game
  };
  
  

  console.log(Math.min(imagePosition, 100));

  return (
    <div
      className="app_container"
      style={{
        fontFamily: "Poppins",
        textAlign: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      {isGameActive ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 50,
              marginBottom: 0,
            }}
            className="p-2"
          >
            <img src={SANTALOGO} height={110} />
            {/* <img src={HNB} height={50} /> */}
          </div>
          {/* <div className="d-flex justify-content-center">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#F9FFFE",
                width: "150px",
                borderRadius: 10,
                paddingBlock: 5,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Your Level
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "1.8em",
                  color:
                    points > 50
                      ? points > 100
                        ? points > 150
                          ? "red"
                          : "orange"
                        : " blue"
                      : "green",
                }}
              >
                {points > 50
                  ? points > 100
                    ? points > 150
                      ? STRINGS.HIGH[langaugeCode]
                      : STRINGS.MEDIUM[langaugeCode]
                    : STRINGS.MEDIUM[langaugeCode]
                  : STRINGS.LOW[langaugeCode]}
              </div>
            </div>
          </div> */}
          {/* <p
            className="mt-2"
            style={{
              fontWeight: "bold",
              fontSize: timeLeft < 4 ? "19px" : "17px",
              color: timeLeft < 4 ? "red" : "#F5F704",
            }}
          >
            <img
              src={CLOCK}
              height={timeLeft < 4 ? 45 : 40}
              className={`${
                timeLeft < 4 &&
                "animate__animated animate__headShake animate__fast animate__infinite"
              }`}
            />{" "}
            {timeLeft}s
          </p> */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                position: "relative",
                width: "65px",
                borderRadius: "8px",
                height: "300px",
                border: "4px ridge black",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                marginTop: "20px",

                background: `${
                  imagePosition != 100
                    ? `linear-gradient(black ${
                        100 - Math.min(imagePosition, 100)
                      }%, red , orange, green )`
                    : `linear-gradient(red , orange, green)`
                }`,
                // background: `linear-gradient(
                //   to top,
                //   ${
                //     imagePosition > 40
                //       ? imagePosition > 80
                //         ? "red"
                //         : "orange"
                //       : "green"
                //   } ${Math.min(imagePosition, 100)}%,
                //   white ${Math.min(imagePosition, 100)}%
                // )`,
              }}
            ></div>
            <img
              style={{ position: "absolute", marginLeft: 106 }}
              height={340}
              src={SANTA}
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {/* Gradient Bar with Thicker Horizontal Lines */}
            <div
              style={{
                zIndex: 10,
                position: "relative",
                width: "65px",
                borderRadius: "8px",
                height: "395px",
                border: "4px ridge black",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                marginTop: "10px",
                background: `${
                  1 === 1
                    ? `linear-gradient(black ${
                        100 - Math.min(imagePosition, 100)
                      }%, transparent 0%), 
       repeating-linear-gradient(
         transparent, 
         transparent 18px, 
         rgba(0, 0, 0, 1) 22px,  /* Fully black lines */
         rgba(0, 0, 0, 1) 26px   /* Fully black lines */
       ), 
       linear-gradient(red, orange, green)`
                    : `linear-gradient(red, orange, green), 
       repeating-linear-gradient(
         transparent, 
         transparent 18px, 
         rgba(0, 0, 0, 1) 22px,  /* Fully black lines */
         rgba(0, 0, 0, 1) 26px   /* Fully black lines */
       )`
                }`,
                backgroundBlendMode: "normal",
              }}
            ></div>

            {/* Santa Image */}
            <img
              style={{ position: "absolute", marginLeft: 135, marginTop: 40, zIndex: 1 }}
              height={370}
              src={SANTA}
            />
          </div>

          <div>
            <img
              className="animate__animated animate__pulse animate__infinite animate__slow mt-4"
              height={80}
              src={MIC}
            />
          </div>
        </>
      ) : (
        <>
          <div className="d-block p-2">
            {/* <img src={HNB} height={50} /> <br /> */}
            {!(timeLeft === 0 && points > 0) && (
              <>
                <img src={SANTALOGO} height={150} className="mt-1 mb-2" />{" "}
                <br />
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: langaugeCode == 0 && "#FDFDC7",
                      borderRadius: 5,
                      color: langaugeCode == 0 && "black",
                    }}
                    className="px-2 mx-2"
                    onClick={() => setLangaugeCode(0)}
                  >
                    En
                  </span>
                  |{" "}
                  <span
                    style={{
                      backgroundColor: langaugeCode == 1 && "#FDFDC7",
                      borderRadius: 5,
                      color: langaugeCode == 1 && "black",
                    }}
                    className="px-2 mx-2"
                    onClick={() => setLangaugeCode(1)}
                  >
                    සිං
                  </span>
                  |{" "}
                  <span
                    style={{
                      backgroundColor: langaugeCode == 2 && "#FDFDC7",
                      borderRadius: 5,
                      color: langaugeCode == 2 && "black",
                    }}
                    className="px-2 mx-2"
                    onClick={() => setLangaugeCode(2)}
                  >
                    த
                  </span>
                </div>
              </>
            )}
            {timeLeft === 0 && points > 0 ? (
              <div className="mt-4">
                {/* <div className="d-flex justify-content-center">
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#F9FFFE",
                      width: "150px",
                      borderRadius: 10,
                      paddingBlock: 5,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {STRINGS.YOUR_FINAL_LEVEL[langaugeCode]}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.8em",
                        color:
                          points > 50
                            ? points > 100
                              ? points > 150
                                ? "red"
                                : "orange"
                              : " blue"
                            : "green",
                      }}
                    >
                      {points > 50
                        ? points > 100
                          ? points > 150
                            ? STRINGS.HIGH[langaugeCode]
                            : STRINGS.MEDIUM[langaugeCode]
                          : STRINGS.MEDIUM[langaugeCode]
                        : STRINGS.LOW[langaugeCode]}
                    </div>
                  </div>
                </div> */}
                <p
                  className="mt-5 mb-5"
                  style={{
                    color: "white",
                    maxWidth: "400px",
                    textAlign: "center",
                    color: "#FDFDC7",
                    fontSize: "20px",
                    paddingTop: "70px",
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.FINAL_MSG[langaugeCode]}
                </p>
                <div className="d-flex justify-content-center">
                  <p
                    className="mt-2 mb-4"
                    style={{
                      color: "white",
                      maxWidth: "400px",
                      textAlign: "center",
                    }}
                  >
                    {STRINGS.FINAL_DESCRIPTION[langaugeCode]}
                  </p>
                </div>
                <p
                  style={{
                    color: "white",
                    maxWidth: "400px",
                    textAlign: "center",
                  }}
                >
                   {STRINGS.SHARE_VIA[langaugeCode]}
                </p>
                <FacebookShareButton
                  className="px-2"
                  url={window.location.href}
                  quote="fdsf"
                  title="🎉 I just helped bring the sound of joy to a child! 🎧💖"
                >
                  {/* <button
                    style={{
                      backgroundColor: "#4267B2",
                      color: "white",
                      fontSize: "14px",
                      padding: "10px 20px",
                      borderRadius: 5,
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    <FaFacebook style={{ fontSize: "20px", marginRight: 10 }} />{" "}
                    Share on FB
                  </button> */}
                  <FaFacebookF style={{backgroundColor: 'white', borderRadius: '50%', padding: "4px"}} size={40}/>
                  {/* <FacebookIcon size={40} round /> */}
                </FacebookShareButton>
                <WhatsappShareButton
                  className="px-2"
                  url={window.location.href}
                  quote="fdsf"
                  title={`By using my joyous voice, I have gifted someone a chance to hear the sound of joy for the first time. 
Its your chance to aboard the HNB Santa Brigade !\n\n`}
                >
                  {/* <WhatsappIcon style={{backgroundColor: 'white'}} size={40} round /> */}
                  <FaWhatsapp style={{backgroundColor: 'white', borderRadius: '50%', padding: "4px"}} size={40}  />
                </WhatsappShareButton>
                <TwitterShareButton
                  className="px-2"
                  url={window.location.href}
                  title={`By using my joyous voice, I have gifted someone a chance to hear the sound of joy for the first time. 
Its your chance to aboard the HNB Santa Brigade ! \n\n`}
                >
                  <FaTwitter style={{backgroundColor: 'white', borderRadius: '50%', padding: "4px"}} size={40}  />
                  {/* <TwitterIcon size={40} round />{" "} */}
                </TwitterShareButton>
                <EmailShareButton
                  className="px-2"
                  subject={"I just helped bring the sound of joy to a child!"}
                  body={`By using my joyous voice, I have gifted someone a chance to hear the sound of joy for the first time. 
Its your chance to aboard the HNB Santa Brigade ! \n\n ${window.location.href}`}
                >
                  <IoMdMail style={{backgroundColor: 'white', borderRadius: '50%', padding: "4px"}} size={40}  />
                  {/* <EmailIcon   /> */}
                </EmailShareButton>
              </div>
            ) : (
              <div>
                <p className="fs-6 mt-2" style={{ color: "white" }}>
                  {STRINGS.SUBTITLE[langaugeCode]}
                </p>
                <div className="fs-5 mt-4" style={{ color: "#F5F704" }}>
                  {STRINGS.STEP[langaugeCode]} 01:
                </div>
                <div style={{ color: "white" }}>
                  {" "}
                  {STRINGS.STEP_ONE[langaugeCode]}
                </div>
                <div className="fs-5 mt-2" style={{ color: "#F5F704" }}>
                  {STRINGS.STEP[langaugeCode]} 02:
                </div>
                <div style={{ color: "white" }}>
                  {STRINGS.STEP_TWO[langaugeCode]}
                </div>
                <div className="fs-5 mt-2" style={{ color: "#F5F704" }}>
                  {STRINGS.STEP[langaugeCode]} 03:
                </div>
                <div style={{ color: "white" }}>
                  {STRINGS.STEP_THREE[langaugeCode]}
                </div>
                <div className="mt-4" style={{ color: "white" }}>
                  {STRINGS.FOOTER_DESCRIPTION[langaugeCode]}
                </div>
      <PlayerCount />
                <img
                  className="animate__animated  animate__tada animate__delay-4s "
                  height={60}
                  onClick={startGame}
                  src={STRT_BTN}
                />
              </div>

              
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
