export const STRINGS = {
  SUBTITLE: [
    "Follow the steps to help a child listen to sound of joy",
    "දරුවන්ට සතුටෙහි හඬ ඇසෙන්නට පහත පියවර අනුගමනය කරන්න",
    "பிள்ளைகள் மகிழ்ச்சியின் குரலை கேட்பதற்கு கீழ்வரும் படிமுறைகளை பின்பற்றுங்கள்",
  ],
  STEP: ["STEP", "පියවර", "படி"],
  STEP_ONE: [
    "Switch the mic on",
    "මයික්‍රෝෆෝනය ON කරන්න",
    "மைக்ரோபோனை ON செய்யுங்கள்",
  ],
  STEP_TWO: [
    "Say HO! HO! HO! loudly",
    "Ho! Ho! Ho! යනුවෙන් හඬනගා කියන්න",
    "Ho! Ho! Ho! என சத்தமாகக் கூறுங்கள்",
  ],
  STEP_THREE: [
    "Watch the decibel meter go up",
    "Decibel මීටරය උපරිමය සටහන් වෙයි දැයි බලන්න",
    "Decibel மீட்டர் உச்சத்தை தொடுகின்றதா என்று பாருங்கள்",
  ],
  YOUR_LEVEL: ["Your Level", "ඔබේ මට්ටම", "உங்கள் நிலை"],
  YOUR_FINAL_LEVEL: [
    "Your Final Level",
    "ඔබේ අවසන් මට්ටම",
    "உங்கள் இறுதி நிலை",
  ],
  LOW: ["LOW", "අඩු", "குறைந்த"],
  MEDIUM: ["MEDIUM", "මධ්යම", "நடுத்தர"],
  HIGH: ["HIGH", "ඉහළ", "உயர்"],
  FOOTER_DESCRIPTION: [
    "When the decibel bar hits the high, we at HNB together with VISA will give a hearing aid to a child who needs it dearly",
    "ඔබේ හඬ Decibel මීටරයේ උපරිමය සටහන් වනවිට, Visa හා එක්ව HNB අපි, සතුටේ හඬ මෙතෙක් නෑසුණු ආදරණීය දරුවන් පිරිසකට ශ්‍රවණාධාර පිරිනමන්නෙමු!",
    "உங்கள் குரல் Decibel மீட்டரின் உச்சத்தைத் தொடுகையில்,Visa உடன் இணைந்து HNB நாம், மகிழ்ச்சியின் குரலை இதுவரை கேட்டிராத பிள்ளைகளுக்கு செவிப்புலன் கருவிகளை பரிசளிக்கவுள்ளோம்!",
  ],
  FINAL_MSG: ["Your HO! HO! HO! was perfect! Thanks for spreading joy.","ඔබේ සතුට හඬේ උපරිමය සටහන් විය!", "உங்கள் மகிழ்ச்சியின் குரல் உச்சத்தைத் தொட்டது!"],
  FINAL_DESCRIPTION: [
    "Spread the Joy! Click 'Share' to let your friends know how you helped bring the sound of joy to a child. Together, we can make a difference!",
    "තව තවත් ආදරණීය දරුවන්ට සතුටේ හඬ ඇසෙන්නට ඔබේ මිතුරන් සමඟත් Share කරන්න.ඔබට සුබ නත්තලක් වේවා!",
    "மேலும் பல அன்புக்குரிய பிள்ளைகள் மகிழ்ச்சியின் குரலை கேட்பதற்கு உங்கள் நண்பர்களுடனும் Share செய்யுங்கள். மகிழ்ச்சியின் குரலை வழங்க நண்பர்களோடு கைகோர்ப்போம்! உங்களுக்கு இனிய நத்தார் வாழ்த்துக்கள்!",
  ],
  SHARE_VIA:["Share via","Share කරන්න","Share via"]
};
